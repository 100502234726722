export const MyData = {
  contact: [
    {
      type: 'phone',
      content: '+1 617-606-1989',
    },
    {
      type: 'email',
      content: 'j.dandyloper@gmail.com',
    },
  ],
  social: ['LinkedIn', 'Github', 'Resume'],
  projects: [
    {
      id: 1,
      name: 'Robin - Outcome Tracking',
      role: 'Fullstack Engineer',
      skills: 'React, Node.JS, Firebase, Material-UI',
      thumbnail:
        'https://firebasestorage.googleapis.com/v0/b/jadekang-portfolio.appspot.com/o/robin-app-resized.png?alt=media&token=9d235dc1-d7ce-4313-a7c6-5b27bfb78273',
      released: 'September 20, 2020',
      github: 'https://github.com/daisyy125/robin-students',
      deployed: 'https://app.myrobin.io/sign-up',
      content: (
        <>
          <p>
            Since I left my CPA behind to pursue my dream job to be a tech
            startup founder, I tried to build and launch a few platforms in the
            mentoring and networking ecosystem. Without legitimate software
            engineering knowledge and experience, I failed and only burned cash.
            Upon my completion of a Software Engineering Immersive program, with
            more than basic knowledge in CS and software engineering, I came up
            with a better product idea - building a SAAS for coding bootcamps
            where students can practice algorithms, share their learnings and
            job search journeys with their career advisors and institutions can
            track students’ performance and effectively get insights in
            students’ job search process and results in one centralized place.
          </p>{' '}
          <p>
            Upon gaining some work experience using React and having learned
            JavaScript, HTML, CSS, NodeJS, and Firebase from GA, I was finally
            able to develop. As a co-founder and a frontend engineer, I led
            preliminary user research and interviews, gathered business
            requirements, then built the application’s frontend using our
            initial design. During the MVP development, I solely contributed to
            the frontend of the application using React and Bootstrap. Once we
            decided to rebuild our student-side application frontend with our
            own custom Robin design and restructure our database and APIs, I
            transitioned from frontend engineer to fullstack engineer. As a new
            fullstack engineer, I was responsible for designing our database
            with Firebase (NoSQL) and building our endpoints in NodeJS using
            firebase functions for the backend. Additionally, I was the main
            developer for turning Figma designs into code by designing the
            frontend UI of the new Robin platform using React and Material-UI.
          </p>
          <p>
            During the MVP development, I was still very inexperienced. I only
            had 6 months of frontend development experience and couldn’t
            effectively use React, Bootstrap, or NodeJS. Therefore, I
            experienced a very steep learning curve, especially when building
            our own IDE to run a user’s code input. Within 2 months after the
            initial Robin application release, I was able to build the frontend
            UI using React more efficiently and refactor the application to be
            highly modular. The real challenges at this time were with
            authentication of various bootcamp students and securely updating
            and maintaining their sensitive data.
          </p>
        </>
      ),
    },
    {
      id: 2,
      name: 'Robin - Outcome Management System',
      role: 'Fullstack Engineer',
      skills: 'React, Node.JS, Firebase, Material-UI',
      thumbnail:
        'https://firebasestorage.googleapis.com/v0/b/jadekang-portfolio.appspot.com/o/robin-app-outcome.png?alt=media&token=dbc37c5b-b0c8-48c0-a722-cdb5b78f1762',
      released: 'September 20, 2020',
      github: 'https://github.com/daisyy125/robin-students',
      deployed: 'https://app.myrobin.io/sign-up',
      content: (
        <>
          <p>
            During my study at General Assembly, my colleagues and I attended
            many career service related meetings and performed various
            assignments every week as part of our coding bootcamp program. It
            was apparent that while 60 students were assigned to one single
            career advisor, there was no tool for the advisor to quickly view
            each student’s career service tasks and additional performance. As
            soon as we acquired enough student users, we reached out to a couple
            of mid-sized bootcamps and decided to build a centralized platform
            for career advisors to monitor students’ outcome performance,
            portfolios, and outcome activities.
          </p>{' '}
          <p>
            Upon gaining some work experience using React and having learned
            JavaScript, HTML, CSS, NodeJS, and Firebase from GA, I was finally
            able to develop. As a co-founder and a frontend engineer, I led
            preliminary user research and interviews, gathered business
            requirements, then built the application’s frontend using our
            initial design. During the MVP development, I solely contributed to
            the frontend of the application using React and Bootstrap. Once we
            decided to rebuild our student-side application frontend with our
            own custom Robin design and restructure our database and APIs, I
            transitioned from frontend engineer to fullstack engineer. As a new
            fullstack engineer, I was responsible for designing our database
            with Firebase (NoSQL) and building our endpoints in NodeJS using
            firebase functions for the backend. Additionally, I was the main
            developer for turning Figma designs into code by designing the
            frontend UI of the new Robin platform using React and Material-UI.
          </p>
          <p>
            During the MVP development, I was still very inexperienced. I only
            had 6 months of frontend development experience and couldn’t
            effectively use React, Bootstrap, or NodeJS. Therefore, I
            experienced a very steep learning curve, especially when building
            our own IDE to run a user’s code input. Within 2 months after the
            initial Robin application release, I was able to build the frontend
            UI using React more efficiently and refactor the application to be
            highly modular. The real challenges at this time were with
            authentication of various bootcamp students and securely updating
            and maintaining their sensitive data.
          </p>
        </>
      ),
    },
    {
      id: 3,
      name: 'Robin - Website',
      role: 'Fullstack Engineer',
      skills: 'React, Node.JS, Firebase, Material-UI',
      thumbnail:
        'https://firebasestorage.googleapis.com/v0/b/jadekang-portfolio.appspot.com/o/robin-website-resized.png?alt=media&token=e513e11b-6a91-4553-aa58-46c9d009f35e',
      released: 'July 21, 2020',
      github: 'https://github.com/jadekang0611/robin-landings',
      deployed: 'https://myrobin.io/students',
      content: (
        <>
          <p>
            After we released our first Robin Application in late March 2020, we
            experienced fast growth in the bootcamp community. Students in
            various coding bootcamps such as General Assembly, LearningFuze,
            Redwood Academy, Springboard, HackReactor, and AppAcademy discovered
            our application and used it daily to practice algorithms and
            computer science. As we were getting 20 new signups every day
            without engaging in any marketing, we started to see an increased
            number of general questions about the application and requests for
            specific needs for some bootcamps. Up until this point, we could
            easily address questions and didn’t pay much attention to
            communicating with users. Starting in June, my cofounder and I were
            invited to speak at many startup events and AMAs. Then, we realized
            that it would have been better if we could make our product
            information and our vision more accessible to the public with our
            brand identity. That’s when we gathered more business requirements
            and started to work with our UI designer for our public website.
          </p>
          <p>
            Before our big AMA event at CareerKarma in July, we had to build and
            deploy our website within 2 weeks. Therefore, we had to properly
            allocate our tasks among 3 developers and decide which css framework
            to use and architect the overall structure of the frontend with
            React. I configured our github repository, branches, and set up our
            local environment. Based on the design and team discussion, I
            discovered three pages that had a consistent layout with a few small
            differences and thus, I built the frontend with more modular
            components and styles. I built the site using React and Bootstrap
            for our frontend. As our senior developer was spending the majority
            of her time on the next version of our enterprise application, I
            performed all code reviews, tested the site, and passed or failed
            the code based on acceptance criteria.
          </p>
          <p>
            Having a time constraint and no responsive designs for tablet or
            mobile screens, we had to quickly but accurately guess and target
            each media query for the responsiveness of the site to be seamless
            based on the original Figma design. To reduce duplicate styles for
            the list of media queries and to ensure efficient development, I
            suggested and implemented one centralized location that holds
            specific styles for all media queries.
          </p>
        </>
      ),
    },
    {
      id: 4,
      name: 'Tempture - Web Application',
      role: 'Fullstack Engineer',
      skills: 'JS, React, Material-UI, Node.JS, MongoDB, JWT, Express',
      thumbnail:
        'https://firebasestorage.googleapis.com/v0/b/jadekang-portfolio.appspot.com/o/tempture-app.png?alt=media&token=e6f5b0c0-3fce-4f09-8335-b5e6b83ea1dd',
      released: 'November 7, 2020',
      github: 'https://github.com/jadekang0611/temp-track',
      deployed: 'https://tempture.herokuapp.com/',
      content: (
        <>
          <p>
            Due to the unchanging severity of COVID-19, the Korean government
            requires every business to take visitors' or customers' personal
            information and temperatures and maintain all records. Many
            businesses have devices to capture a customer's QR code to get their
            personal information from their phones via Kakao or Naver. However,
            small private businesses can't afford such a device. Therefore,
            people at many places take each visitor's temperature and manually
            track their personal information and temperatures on paper everyday.
            Furthermore, they often use the same paper that has the previous
            information of many visitors over two or three days already logged.
            Each person's personal information is accessible to anyone walking
            by. Upon performing user research and interviews, I discovered that
            the manual entries of each visitor's temperature takes time, are
            often illegible, and get lost, even though government officials
            often request small businesses to send them a compiled report that
            shows all entries over a certain period.
          </p>
          <p>
            As soon as I gathered business requirements, I designed a simple
            user flow and structured my data models including the user model,
            log model, and customer model which were ultimately designed in
            NodeJS, Mongoose, and MongoDB. To practice and improve my skills in
            MongoDB and NodeJS, I built my REST API using NodeJS, MongoDB,
            Mongoose, and Express instead of with Firebase. I tested and
            validated each endpoint using Postman. I also enhanced security and
            authentication of the application by implementing custom user input
            validation using Joi, bcrypt, and JWT. Upon the completion of the
            backend, I built the frontend with React and Material-UI and used
            Axios to fetch data from the API. Lastly, I built a PDF report file
            generator feature using jsPDF that lets a user generate a
            temperature log based on search criteria that gets returned in PDF
            format on the client-side.
          </p>
          <p>
            From building this application to deploying it live, I’ve
            encountered several roadblocks: (1) working with jsPDF in React and
            (2) working with MongoDB. Firstly, enabling the HTML5 client-side
            solution for PDF generation using jsPDF with React in Korean was
            very challenging mostly due to lack of documentation and outdated
            solutions all over the internet. Another one is that being more
            familiar with APIs built using Firebase functions, my knowledge in
            MongoDB and NodeJS was disappearing. To turn it into an opportunity,
            I reviewed the MongoDB documentation, practiced querying, and
            somewhat slowly, but accurately, built my backend following the MVC
            structure. This was quite a challenging project but at the end it
            turned out to be one of the most rewarding projects that I have ever
            completed.
          </p>
        </>
      ),
    },
    {
      id: 5,
      name: 'Tempture - Website',
      role: 'Fullstack Engineer',
      skills: 'JavaScript, React, Heroku, Material-UI',
      thumbnail:
        'https://firebasestorage.googleapis.com/v0/b/jadekang-portfolio.appspot.com/o/tempture-website-resized.png?alt=media&token=f76922bc-31da-4eee-a70d-e1b1c1dfa7ed',
      released: 'November 7, 2020',
      github: 'https://github.com/jadekang0611/temp-track',
      deployed: 'https://tempture.herokuapp.com/',
      content: (
        <>
          <p>
            Initially, I didn’t plan to create a full website for the Tempture
            application. However, once I released the MVP version to a couple of
            eSmart Haebeob Learning centers in Seoul, I was contacted by some
            small bakery and cafe owners that own major brands - Tous Le Jours
            and Pascucci - in Gangnam, Seoul. To provide a better user
            experience and share more useful information, I decided to design
            and build the main Tempture website where people can learn about the
            application, COVID19 status, and also simply download custom
            designed posters for free to increase public safety during this
            unprecedented time.
          </p>
          <p>
            Because I wanted the development of the website to take less than 3
            days without any unnecessary road blocks, I decided to use React and
            Material UI for my frontend and deployed it to Heroku. I used Canva
            to design my custom posters.
          </p>
          <p>
            Despite the fact that I did not have a UI design for the project,
            using Material-UI and design concepts, I believe that I picked
            appropriate colors and designed the site to be user friendly,
            spending no more than 15 hours on this project.
          </p>
        </>
      ),
    },
    {
      id: 6,
      name: 'Edubloc - Mobile Application',
      role: 'Product/Project Manager',
      skills: 'JIRA, Agile, Scrum, Figma, Github, Angular, Ionic',
      thumbnail:
        'https://firebasestorage.googleapis.com/v0/b/jadekang-portfolio.appspot.com/o/edubloc-mobile.png?alt=media&token=b58144fe-8859-46a0-8e40-67087d839b68',
      released: 'October 15, 2020',
      github: 'https://github.com/jadekang0611/edubloc-mobile',
      deployed: '',
      content: (
        <>
          <p>
            My former coworker who works closely with blockchain startups in
            Seoul reached out to me in May 2020 about whether or not I would be
            interested in assisting an education blockchain startup with
            building and releasing their product to the market. With my passion
            in transparency in education and modern education technology, after
            3 in-person meetings with the founders of the startup called
            Edubloc, Inc., they decided to put me in charge of running the
            project with responsibilities surrounding redesigning the product
            concept and managing the frontend development.
          </p>
          <p>
            Both the company and I knew that I was the best candidate for the
            project since I have solid experience in developing in an agile
            environment, running standups, managing projects, hiring personnel,
            and most importantly building a startup. The client had a failed
            product that was not functional and was missing source code after
            burning approximately $50,000 over the course of one year.
          </p>
          <p>
            Under the stakeholders’ new plan to apply to a government-run demo
            event in November 2020, instead of using the previous generic
            roadmap and incomplete business requirements, I guided the client
            into solidifying business requirements that were realistic and
            aligned with the company’s short-term vision. Then I turned those
            into technical requirements that could be accomplished with the
            company’s budget and timeline. I determined the most appropriate
            technologies to be Angular or React using Bootstrap for the web
            application and Ionic with Angular for the mobile application
            considering that both applications would be MVPs and were needed to
            be developed within a short amount of time.
          </p>
          <p>
            I presented the selected technologies to the stakeholders and, upon
            approval, I started to interview candidates based on their technical
            skills, communication skills, commitment, and knowledge of
            blockchain. Once developers and a UI designer were onboard, as a
            Scrum Master, I ran a daily standup with both the developers and the
            designer to ensure a successful project completion and identified
            any roadblocks while keeping track of individuals’ status and
            backlogs. As a Project Manager / Product Owner, I gave continuous
            status updates to the stakeholders with an intention to receive
            feedback from them as quickly as possible to minimize any setbacks.{' '}
          </p>
          <p>
            Challenge/Success: With the proper execution plan and agile
            strategy, I was able to meet the project deadline of 2 months and
            delivered the final product to the client. Starting in the earlier
            phase of the project, I collaborated with Samsung developers and
            DreamSecurity, a leading Decentralized Identifiers software company,
            to discuss the user flows, business requirements, and the frontend
            development of the applications. I had a great opportunity to
            successfully turn someone’s idea into a tangible business product as
            an external Project Manager / Product Owner, but at the same time,
            this experience reminded me again that everyone involved in a
            product’s development must clearly understand and share business
            vision and priorities to ensure that product development is
            optimized.
          </p>
        </>
      ),
    },
    {
      id: 7,
      name: 'Edubloc - Web Application',
      role: 'Product/Project Manager',
      skills: 'JIRA, Agile, Scrum, Figma, Github, Angular, Ionic',
      thumbnail:
        'https://firebasestorage.googleapis.com/v0/b/jadekang-portfolio.appspot.com/o/edubloc-web-application.png?alt=media&token=df77c00f-dd4a-4989-9ecb-d88353e6c713',
      released: 'November 1, 2020',
      github: 'https://github.com/jadekang0611/edublock-web-app',
      deployed: '',
      content: (
        <>
          <p>
            My former coworker who works closely with blockchain startups in
            Seoul reached out to me in May 2020 about whether or not I would be
            interested in assisting an education blockchain startup with
            building and releasing their product to the market. With my passion
            in transparency in education and modern education technology, after
            3 in-person meetings with the founders of the startup called
            Edubloc, Inc., they decided to put me in charge of running the
            project with responsibilities surrounding redesigning the product
            concept and managing the frontend development.
          </p>
          <p>
            Both the company and I knew that I was the best candidate for the
            project since I have solid experience in developing in an agile
            environment, running standups, managing projects, hiring personnel,
            and most importantly building a startup. The client had a failed
            product that was not functional and was missing source code after
            burning approximately $50,000 over the course of one year.
          </p>
          <p>
            Under the stakeholders’ new plan to apply to a government-run demo
            event in November 2020, instead of using the previous generic
            roadmap and incomplete business requirements, I guided the client
            into solidifying business requirements that were realistic and
            aligned with the company’s short-term vision. Then I turned those
            into technical requirements that could be accomplished with the
            company’s budget and timeline. I determined the most appropriate
            technologies to be Angular or React using Bootstrap for the web
            application and Ionic with Angular for the mobile application
            considering that both applications would be MVPs and were needed to
            be developed within a short amount of time.
          </p>
          <p>
            I presented the selected technologies to the stakeholders and, upon
            approval, I started to interview candidates based on their technical
            skills, communication skills, commitment, and knowledge of
            blockchain. Once developers and a UI designer were onboard, as a
            Scrum Master, I ran a daily standup with both the developers and the
            designer to ensure a successful project completion and identified
            any roadblocks while keeping track of individuals’ status and
            backlogs. As a Project Manager / Product Owner, I gave continuous
            status updates to the stakeholders with an intention to receive
            feedback from them as quickly as possible to minimize any setbacks.{' '}
          </p>
          <p>
            Challenge/Success: With the proper execution plan and agile
            strategy, I was able to meet the project deadline of 2 months and
            delivered the final product to the client. Starting in the earlier
            phase of the project, I collaborated with Samsung developers and
            DreamSecurity, a leading Decentralized Identifiers software company,
            to discuss the user flows, business requirements, and the frontend
            development of the applications. I had a great opportunity to
            successfully turn someone’s idea into a tangible business product as
            an external Project Manager / Product Owner, but at the same time,
            this experience reminded me again that everyone involved in a
            product’s development must clearly understand and share business
            vision and priorities to ensure that product development is
            optimized.
          </p>
        </>
      ),
    },
  ],
};
